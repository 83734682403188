import { useState, useEffect } from "react";
import { Collapse } from "react-bootstrap";
import Banner from "components/Banner/Banner"; // Substitua pelo caminho correto
import SimpleRow from "components/SimpleRow/SimpleRow";

function Page() {
  const [isCollapsed, setCollapsed] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleCollapse = () => {
    setCollapsed(!isCollapsed);
  };

  return (
    <>
      <Banner text="Sampe Educa" />
      <SimpleRow backgroundColor="#f5f8ff" className="pb-5">
        <div className="col-md-12">
          <h1>Curso Compósitos Poliméricos</h1>
          <p>
            Este curso é composto de 21 aulas ministradas por professores de 19
            Instituições diferentes.
          </p>
          <p>
            As aulas ocorrerão ao vivo, de fevereiro a dezembro de 2024, sempre
            às Terças-feiras (16-17 h). Todas são de acesso livre, com
            Transmissão aberta pelo Youtube, e participação da audiência.
          </p>
          <p>
            Neste curso de "Compósitos Poliméricos", serão abordados conceitos
            fundamentais, matrizes, reforços, interfaces, processos de
            fabricação, aplicações, entre outros. Clique no botão abaixo para
            saber os temas, professores e datas de cada aula.
          </p>
          <button
            style={{
              backgroundColor: "#006699",
              color: "#ffffff",
              border: 0,
              margin: "0 5px 0.75em 0",
              borderRadius: "5px",
              padding: "10px 10px",
              textAlign: "center",
              textDecoration: "none",
              overflow: "hidden",
              height: "40px",
              display: "inline-block",
            }}
            className="btn btn-link col-md-12"
            onClick={handleCollapse}
            aria-controls="courseDetails"
            aria-expanded={isCollapsed}
          >
            {isCollapsed ? "Mostrar Sumário" : "Ocultar Sumário"}
          </button>
          <div className="pb-3">
            <Collapse in={!isCollapsed}>
              <div id="courseDetails">
                <ol style={{ listStyle: "none", margin: "0", padding: "0" }}>
                  <li>
                    <strong>
                      1.{" "}
                      <a
                        href="https://www.youtube.com/watch?v=VnyYpTULvh0"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Introdução e conceitos fundamentais (20/Fev)
                      </a>
                    </strong>{" "}
                    – Mirabel Rezende (UNIFESP) e Sandro Amico (UFRGS)
                  </li>
                  <li>
                    <strong>2. Matrizes para compósitos</strong>
                    <ol style={{ marginLeft: "20px", listStyle: "none" }}>
                      <li>
                        <strong>
                          2.1.{" "}
                          <a
                            href="https://youtube.com/live/oxJGjHZjAac"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            Matrizes termoplásticas (05/Mar)
                          </a>{" "}
                        </strong>
                        - José Ricardo Tarpani (USP - São Carlos)
                      </li>
                      <li>
                        <strong>
                          2.2.{" "}
                          <a
                            href="https://youtube.com/live/LiN1PwxX8ZU"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            Matrizes termorrígidas (19/Mar)
                          </a>{" "}
                        </strong>
                        - Sérgio Pezzin (UDESC)
                      </li>
                      <li>
                        <strong>
                          2.3.{" "}
                          <a
                            href="https://youtube.com/live/dIH3kmGIotY"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            Matrizes elastoméricas (02/Abr)
                          </a>{" "}
                        </strong>
                        - Janaina Crespo (UCS)
                      </li>
                      <li>
                        <strong>
                          2.4.{" "}
                          <a
                            href="https://youtube.com/live/JJtVWyFppiU"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Matrizes biodegradáveis e biocompósitos (14/Mai)
                          </a>{" "}
                        </strong>{" "}
                        – Derval Rosa (UFABC)
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>3. Reforços para compósitos</strong>
                    <ol style={{ marginLeft: "20px", listStyle: "none" }}>
                      <li>
                        <strong>
                          3.1.{" "}
                          <a
                            href="https://youtube.com/live/KYBXTLdl8tQ"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            Partículas e fibras de madeira e derivados (16/Abr)
                          </a>{" "}
                        </strong>
                        - Washington Magalhaes (EMBRAPA)
                      </li>
                      <li>
                        <strong>
                          3.2.{" "}
                          <a
                            href="https://youtube.com/live/qObkMyusPUw"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            Fibras sintéticas (30/Abr)
                          </a>{" "}
                        </strong>
                        - Luiz Cláudio Pardini (ITA)
                      </li>
                      <li>
                        <strong>
                          3.3.{" "}
                          <a
                            href="https://www.youtube.com/live/26bT99Dawhg"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            Fibras naturais lignocelulósicas (20/Ago)
                          </a>{" "}
                        </strong>
                        – Alcides Leao (UNESP)
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>4. Formas de reforço</strong>
                    <ol style={{ marginLeft: "20px", listStyle: "none" }}>
                      <li>
                        <strong>
                          4.1.{" "}
                          <a
                            href="https://youtube.com/live/zUhVz7apvM8"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            Fibras curtas (28/Mai)
                          </a>{" "}
                        </strong>
                        - Sandra Luz (UnB)
                      </li>
                      <li>
                        <strong>
                          4.2.{" "}
                          <a
                            href="https://youtube.com/live/AvmLgJgaE74"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            Mantas, tecidos & pré-formas (11/Jun)
                          </a>{" "}
                        </strong>
                        - Gerson Marinucci (IPEN)
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>5. Adesão & interface reforço/matriz</strong>
                    <ol style={{ marginLeft: "20px", listStyle: "none" }}>
                      <li>
                        <strong>
                          5.1.{" "}
                          <a
                            href="https://youtube.com/live/8YUQjt5XbxQ"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            Interface, interfase & tratamentos de superfície
                            (25/Jun)
                          </a>{" "}
                        </strong>{" "}
                        – Rodrigo Oréfice (UFMG)
                      </li>
                      <li>
                        <strong>
                          5.2.{" "}
                          <a
                            href="https://www.youtube.com/watch?v=cjOrWx_NuWQ"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            Adesão & análises de interface (09/Jul)
                          </a>{" "}
                        </strong>{" "}
                        - Guilherme Barra (UFSC)
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>
                      6.{" "}
                      <a
                        href="https://www.youtube.com/watch?v=KUFpZrJS9es"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        Nanocompósitos (06/Ago)
                      </a>{" "}
                    </strong>{" "}
                    - Fabio Passador (UNIFESP)
                  </li>
                  <li>
                    <strong>7. Compósitos estruturais</strong>
                    <ol style={{ marginLeft: "20px", listStyle: "none" }}>
                      <li>
                        <strong>7.1. Laminados compósitos (03/Set)</strong> -
                        Flamino Levy Neto
                      </li>
                      <li>
                        <strong>7.2. Painéis-sanduíche (17/Set)</strong> - Túlio
                        Panzera (UFSJ)
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>8. Processos de fabricação</strong>
                    <ol style={{ marginLeft: "20px", listStyle: "none" }}>
                      <li>
                        <strong>
                          9.1. Fabricação de compósitos termoplásticos (01/Out)
                        </strong>{" "}
                        - Edson Botelho (UNESP)
                      </li>
                      <li>
                        <strong>
                          9.2. Fabricação de compósitos termorrígidos (15/Out)
                        </strong>{" "}
                        - Amanda Oliveira (UFPel)
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>
                      9. Efeitos ambientais, degradação & envelhecimento
                      (29/Out)
                    </strong>{" "}
                    – Guilhermino Fechine (Mackenzie)
                  </li>
                  <li>
                    <strong>
                      10. Cargas/aditivos/modificadores de polímeros (12/Nov)
                    </strong>{" "}
                    – Ana Paula Cysne (UFRN)
                  </li>
                  <li>
                    <strong>
                      11. Análises térmicas aplicadas a compósitos (03/Dez)
                    </strong>{" "}
                    - Sebastiao Canevarolo Jr (UFSCar)
                  </li>
                  <li>
                    <strong>12. Reciclagem de compósitos (10/Dez)</strong> -
                    Claudia Merlini (UFSC)
                  </li>
                </ol>
              </div>
            </Collapse>
          </div>
        </div>
      </SimpleRow>
    </>
  );
}

export default Page;
