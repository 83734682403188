import { NavLink, Route } from "react-router-dom";
import SimpleRow from "components/SimpleRow/SimpleRow";
import FormularioInscricaoDesafio from "components/FormularioInscricaoDesafio/FormularioInscricaoDesafio";
import logoDesafio from "resources/logo_desafio.jpg";
import logoDesafioProvaPonte from "resources/img/logo_desafio_prova_ponte_2024.jpeg";
import logoDesafioInovacaoCompositos from "resources/img/logo_desafio_inovacao.jpg";

import regrasProvaDaPonte from "resources/pdfs/regras_prova_da_ponte_2024.pdf";
import notasFinaisProvaDaPonte from "resources/notas_finais_jurados_desafio_academico_2023.xlsx";
import faqProvaDaPonte from "resources/pdfs/faq_prova_da_ponte.pdf";

import regras2022 from "resources/pdfs/desafio_2022/regras.pdf";
import proposta2022 from "resources/pdfs/desafio_2022/proposta.docx";
import projeto2022 from "resources/pdfs/desafio_2022/projeto.docx";
import rankingInovacaoEmCompositos from "resources/rankingInovacaoEmCompositos.xlsx";

function DesafioAcademico() {
  return (
    <>
      <SimpleRow backgroundColor="#f5f8ff">
        <div className="col mb-5 pb-5">
          <Route exact path="/desafioAcademico/">
            <div className="row">
              <div className="col-md-8">
                <h1>Desafio Acadêmico</h1>
                <p>
                  O Desafio Acadêmico tem como meta proporcionar aos alunos, dos
                  cursos relacionados à área de Tecnologia de Materiais, uma
                  experiência real de projeto com materiais e processos
                  avançados, obedecendo regras e especificações
                  preestabelecidas, tendo seu projeto avaliado e validado por
                  uma banca formada por profissionais respeitados da indústria.
                </p>
                <h2>Concursos</h2>
                <p>
                  <ul>
                    <li>
                      <NavLink
                        activeClassName="active-link"
                        to="/desafioAcademico/o-desafio-da-ponte"
                      >
                        {" "}
                        <b> Prova da ponte para estudantes </b>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeClassName="active-link"
                        to="/desafioAcademico/inovacao-em-compositos"
                      >
                        {" "}
                        <b> Inovação em compósitos </b>
                      </NavLink>
                    </li>
                    <li>
                      [Em desenvolvimento] - Prova de manufatura aditiva para
                      estudantes
                    </li>
                    <li>
                      [Em desenvolvimento] - Prêmio experiência de liderança
                      estudantil
                    </li>
                    <li>[Em desenvolvimento] - Concurso cartaz estudantil</li>
                    <li>[Em desenvolvimento] - Desafio Empreendedor</li>
                  </ul>
                </p>
              </div>
              <div
                className="col-md-4"
                style={{
                  display: "flex",
                  paddingBottom: "60px",
                  alignSelf: "center",
                }}
              >
                <img src={logoDesafio} className="img-fluid" alt="" />
              </div>
            </div>
          </Route>
          <Route path="/desafioAcademico/material-auxiliar">
            <h1>Material auxiliar</h1>
            <div className="row">
              <div className="col-md-6">
                <h2>PARTE 1</h2>
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/kBui9LDOFGY"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="true"
                  rel="0"
                ></iframe>
              </div>
              <div className="col-md-6">
                <h2>PARTE 2</h2>
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/jLDIQGgRFps"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="true"
                  rel="0"
                ></iframe>
              </div>
              <div className="col-md-6">
                <h2>PARTE 3</h2>
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/Wv9eup8_sA8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="true"
                  rel="0"
                ></iframe>
              </div>
              <div className="col-md-6">
                <h2>PARTE 4</h2>
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/uGkw39YM1rM"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="true"
                  rel="0"
                ></iframe>
              </div>
            </div>
          </Route>
          <Route path="/desafioAcademico/o-desafio-da-ponte">
            <div className="row">
              <div className="col-md-8">
                <h1>Prova da Ponte</h1>
                <p>
                  Este concurso permite que os alunos projetem, construam e
                  testem uma ponte estrutural em miniatura utilizando vários
                  materiais compósitos, de acordo com um conjunto de regras bem
                  definidas.
                </p>

                <p>
                  A Prova da Ponte é a versão nacional de uma competição
                  conhecida internacionalmente como “Student Bridge Contest”,
                  envolvendo escolas de todo o mundo. Os ganhadores da
                  competição nacional estarão qualificados a participar da final
                  nos Estados Unidos, em que as equipes competem por prêmios e
                  pelo cobiçado troféu SAMPE Student Bridge Champion.
                </p>

                <p>
                  Para participar, as equipes devem preencher o formulário que
                  consta na seção “Inscreva-se”, atentando-se a todas as
                  informações requeridas. Dessa forma, ao finalizar a inscrição,
                  a equipe receberá uma confirmação via e-mail cadastrado.
                </p>
                <a
                  style={{
                    backgroundColor: "#006699",
                    color: "#ffffff",
                    border: 0,
                    margin: "0 5px 0.75em 0",
                    borderRadius: "5px",
                    padding: "15px 10px",
                    textAlign: "center",
                    width: "200px",
                    display: "inline-block",
                  }}
                  download="Regras_Prova_da_Ponte"
                  href={regrasProvaDaPonte}
                  target="_blank"
                  rel="noreferrer"
                >
                  Regras
                </a>
                <a
                  style={{
                    backgroundColor: "#006699",
                    color: "#ffffff",
                    border: 0,
                    margin: "0 5px 0.75em 0",
                    borderRadius: "5px",
                    padding: "15px 10px",
                    textAlign: "center",
                    width: "200px",
                    display: "inline-block",
                  }}
                  download="FAQ_Prova_da_Ponte"
                  href={faqProvaDaPonte}
                  target="_blank"
                  rel="noreferrer"
                >
                  Perguntas Frequentes
                </a>
                <p>
                  Inscrições Encerradas
                </p>
              </div>
              <div className="col-md-4">
                <img src={logoDesafioProvaPonte} className="img-fluid" alt="" />
              </div>
            </div>
          </Route>
          <Route path="/desafioAcademico/inovacao-em-compositos">
            <div className="row">
              <div className="col-md-8">
                <h1>Inovação em Compósitos</h1>
                <p>
                  Este concurso permite que os alunos idealizem, desenvolvam e
                  projetem um produto de natureza inovadora de acordo com um
                  conjunto de regras bem definidas.
                </p>

                <p>
                  O desafio acadêmico “Inovação em Compósitos” é a versão
                  nacional adaptada de uma competição conhecida
                  internacionalmente como “Innovative Application Design of
                  Carbon Fiber Composite Materials in Civil Engineering”,
                  realizado nas edições da China. As primeiras colocações serão
                  premiadas com certificados, acessos a nossa plataforma
                  internacional SAMPE 365, visitas técnicas, cursos em materiais
                  compósitos e muito mais!
                </p>

                <p>
                  Para participar, as equipes devem preencher o formulário de
                  inscrições abaixo, atentando-se a todas as informações
                  requeridas. Dessa forma, ao finalizar a inscrição, a equipe
                  receberá uma confirmação via e-mail cadastrado.”
                </p>
                <a
                  style={{
                    backgroundColor: "#006699",
                    color: "#ffffff",
                    border: 0,
                    margin: "0 5px 0 0",
                    borderRadius: "5px",
                    padding: "15px 10px",
                    textAlign: "center",
                    width: "200px",
                    display: "inline-block",
                  }}
                  href={regras2022}
                  download="I Desafio Acadêmico Inovação em Compósitos 2022"
                >
                  Regras
                </a>
                <a
                  style={{
                    backgroundColor: "#006699",
                    color: "#ffffff",
                    border: 0,
                    margin: "0 5px 0 0",
                    borderRadius: "5px",
                    padding: "15px 10px",
                    textAlign: "center",
                    width: "200px",
                    display: "inline-block",
                  }}
                  href={proposta2022}
                  download="Template Proposta"
                >
                  Template Proposta
                </a>
                <a
                  style={{
                    backgroundColor: "#006699",
                    color: "#ffffff",
                    border: 0,
                    margin: "0 5px 0 0",
                    borderRadius: "5px",
                    padding: "15px 10px",
                    textAlign: "center",
                    width: "200px",
                    display: "inline-block",
                  }}
                  href={projeto2022}
                  download="Template Projeto"
                >
                  Template Projeto
                </a>
                <p className="mt-4">Inscrições Encerradas</p>
                <p className="mt-4">
                  Participem também de{" "}
                  <a
                    style={{ fontWeight: "700" }}
                    href="https://docs.google.com/forms/d/e/1FAIpQLSe2ufRQki8iMd44HLmX6y4zTf4jSJWXk1BVWcptQt0F9mK3GQ/viewform"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    nossa pesquisa{" "}
                  </a>{" "}
                  para conhecermos mais do cenário estudantil dentro das
                  universidades relacionado a materiais compósitos! Esta
                  pesquisa é muito importante para que possamos, juntos,
                  construir um futuro de maior disseminação de conhecimentos dos
                  materiais e processos avançados!
                </p>
                {/*LUGAR PARA COLOCAR A TABELA!!!*/}
                <h2 className="mt-4 mb-4">
                  Ranking Desafio Acadêmico Inovação em Compósitos:
                </h2>
                <table className="table table-striped table-bordered table-sm">
                  <thead className="bg-primary text-white">
                    <tr className="text-center">
                      <th scope="col">Posição</th>
                      <th scope="col">Equipe</th>
                      <th scope="col" colspan="2">
                        Projeto
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1º</th>
                      <td colspan="2">202202024 - UC NeoFiber</td>
                      <td>
                        Quadro de Bicicleta Urbana Reforçado com Fibra de
                        Bananeira
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">2º</th>
                      <td colspan="2">202202023 - UC ResisTeam</td>
                      <td>
                        Prótese com Reforço de Fibra de Sisal Destinada a
                        Paratletas
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">3º</th>
                      <td colspan="2">202202021 - Inova FEI Alpha</td>
                      <td>
                        Projeto de Implante Dentário em Material Nanocompósito
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">4º</th>
                      <td colspan="2">
                        202202027 - Equipe Albatroz AeroDesign
                      </td>
                      <td>
                        Manufatura Aditiva na Produção de Hélices para
                        Aerodesign Utilizando Material Compósito
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">5º</th>
                      <td colspan="2">202202017 - Chimera-PUC</td>
                      <td>
                        Análise Estrutural de um Dormente Ferroviário Elaborado
                        de Material Compósito
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">6º</th>
                      <td colspan="2">202202020 - Jacuí</td>
                      <td>
                        Projeto de uma Lança Eletricamente Isolante Produzida em
                        Material Compósito para Cesta Aérea
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">7º</th>
                      <td colspan="2">202202022 - Compósitos Avançados</td>
                      <td>Aplicação de Compósitos para Engate de Reboque</td>
                    </tr>
                    <tr>
                      <th scope="row">8º</th>
                      <td colspan="2">
                        202202026 - Martin Alejandro Esquitino
                      </td>
                      <td>
                        Estudo Comparativo entre Fibras para Compósito
                        Estrutural para Substituição de Fibras de Vidro
                        Utilizadas em Pranchas de Surfe
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  Para baixar a tabela{" "}
                  <a
                    style={{ fontWeight: "700" }}
                    href={rankingInovacaoEmCompositos}
                    download="Ranking Inovação em Compositos.xlsx"
                  >
                    clique aqui!
                  </a>
                </p>
                {/*TABELA ACABA AQUI*/}
              </div>
              <div className="col-md-4">
                <img
                  src={logoDesafioInovacaoCompositos}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </Route>
          <Route path="/desafioAcademico/edicoes-anteriores">
            <h1>Edições anteriores</h1>
            <p>
              2023 - I DESAFIO ACADÊMICO INOVAÇÃO EM COMPÓSITOS SAMPE BRASIL
            </p>
            <p>2023 - VII DESAFIO ACADÊMICO PROVA DA PONTE SAMPE BRASIL</p>
            <p>2019 - VI DESAFIO ACADÊMICO PROVA DA PONTE SAMPE BRASIL</p>
            <p>2018 - V DESAFIO ACADÊMICO PROVA DA PONTE SAMPE BRASIL</p>
            <p>2017 - IV DESAFIO ACADÊMICO PROVA DA PONTE SAMPE BRASIL</p>
            <p>2016 - III DESAFIO ACADÊMICO PROVA DA PONTE SAMPE BRASIL</p>
            <p>2015 - II DESAFIO ACADÊMICO PROVA DA PONTE SAMPE BRASIL</p>
            <p>2014 - I DESAFIO ACADÊMICO PROVA DA PONTE SAMPE BRASIL</p>
          </Route>
          <Route path="/desafioAcademico/fale-conosco">
            <h1>Contato</h1>
            <p
              className="mt-4 mb-1"
              style={{ display: "flex", alignItems: "center" }}
            >
              <i className="fas fa-envelope text-primary fa-2x mr-2"></i>
              desafioacademico@sampebrasil.org
            </p>
            <p
              className="mb-1"
              style={{ display: "flex", alignItems: "center" }}
            >
              <i className="fab fa-whatsapp text-primary fa-2x mr-2"></i>
              <a
                href="https://wa.me/5511996603613"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                +55 11 99660-3613{" "}
              </a>
            </p>
            <p className="mb-1 mt-4">Siga-nos em nossas redes sociais!</p>
            <p style={{ paddingBottom: "60px" }}>
              <a
                className="text-primary"
                href="https://www.facebook.com/sampebrasiloficial/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook-square fa-3x mr-2"></i>
              </a>
              <a
                className="text-primary"
                href="https://www.youtube.com/channel/UCpNurLJD625AKytq1o1YUeA"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-youtube fa-3x mr-2"></i>
              </a>
              <a
                className="text-primary"
                href="https://www.instagram.com/sampebrasil/?hl=en"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram fa-3x mr-2"></i>
              </a>
              <a
                className="text-primary"
                href="https://www.linkedin.com/company/sampe-brasil/mycompany/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin fa-3x mr-2"></i>
              </a>
            </p>
          </Route>
        </div>
      </SimpleRow>
    </>
  );
}

export default DesafioAcademico;
